import {
  VARIANTS_ORDER,
  SIZES_ORDER,
  WIDTHS_ORDER,
  WEIGHTS_ORDER,
  STYLES_ORDER,
} from 'values';

function sortPredicate(order: string[], valueA: string, valueB: string) {
  const indexA = order.indexOf(valueA);
  const indexB = order.indexOf(valueB);

  if (indexA > indexB) {
    return 1;
  } else if (indexA < indexB) {
    return -1;
  } else {
    return 0;
  }
}

export const variantSortPredicate = sortPredicate.bind(null, VARIANTS_ORDER);
export const sizeSortPredicate = sortPredicate.bind(null, SIZES_ORDER);
export const widthSortPredicate = sortPredicate.bind(null, WIDTHS_ORDER);
export const weightSortPredicate = sortPredicate.bind(null, WEIGHTS_ORDER);
export const styleSortPredicate = sortPredicate.bind(null, STYLES_ORDER);

export function sortFontVariants(variants: Array<string>): Array<string> {
  return variants.sort(variantSortPredicate);
}

export function sortFontSizes(sizes: Array<string>): Array<string> {
  return sizes.sort(sizeSortPredicate);
}

export function sortFontWidths(widths: Array<string>): Array<string> {
  return widths.sort(widthSortPredicate);
}

export function sortFontWeights(weights: Array<string>): Array<string> {
  return weights.sort(weightSortPredicate);
}

export function sortFontStyles(weights: Array<string>): Array<string> {
  return weights.sort(styleSortPredicate);
}

export function sortFontFaces<
  TFace extends {
    variant: string;
    size: string;
    width: string;
    weight: string;
    style: string;
  },
>(fontFaces: TFace[]): TFace[] {
  return fontFaces.sort((faceA, faceB) => {
    let value;

    value = variantSortPredicate(faceA.variant, faceB.variant);

    if (value !== 0) {
      return value;
    }

    value = sizeSortPredicate(faceA.size, faceB.size);

    if (value !== 0) {
      return value;
    }

    value = widthSortPredicate(faceA.width, faceB.width);

    if (value !== 0) {
      return value;
    }

    value = weightSortPredicate(faceA.weight, faceB.weight);

    if (value !== 0) {
      return value;
    }

    value = styleSortPredicate(faceA.style, faceB.style);

    return value;
  });
}

export function sortFontPackages<
  TPackage extends {
    variant: string;
    size: string | null;
    width: string | null;
  },
>(fontPackages: TPackage[]): TPackage[] {
  return fontPackages.sort((packageA, packageB) => {
    let value = variantSortPredicate(packageA.variant, packageB.variant);

    if (value !== 0) {
      return value;
    }

    value = sizeSortPredicate(packageA.size, packageB.size);

    if (value !== 0) {
      return value;
    }

    return widthSortPredicate(packageA.width, packageB.width);
  });
}
