const DATE_TIME_FORMAT = new Intl.DateTimeFormat();

export default function formatDate(value: Date | string | null): string | null {
  if (value == null) {
    return null;
  }

  const date = value instanceof Date ? value : new Date(value);

  return DATE_TIME_FORMAT.format(date);
}
